.container-form-create {
    max-width: 35%;
    height: 25%;
    padding: 50px;
    margin: 20px auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background-color: #f2f7f8;
    border-radius: 15px;
    box-shadow: inset 0 -3em 3em rgba(193, 205, 224, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(114, 147, 218, 0.3);
    padding-bottom: 25px;
}

.label-create {
    color: #393F3F;
    margin-bottom: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 20px;
}

.input-create {
    margin-bottom: 10px;
    height: 35px;
    padding: 5px;
    font-size: 16px;
    width: 80%;
}

.dropdown-create {
    width: 80%;
    height: 35px;
    padding: 5px;
    font-size: 16px;
}

.container-checkbox {
  
    .checkbox-create {
       
      /* Define custom checkbox style */
      &::before {
        content: "";
        display: inline-block;
        width: 30px;
        height: 30px;
        background-color: #393f3f;
        border: 3px solid #393f3f;
      }
    }
    /* Style the custom checkbox when checked */
    .checkbox-create:checked::before {
      background-color: green; /* Change background color when checked */
    }
  }
  
  .log-out-button i {
    position: absolute;
    right: 0;
    width: 100px;
    font-size: 3rem;
    cursor: pointer;
  }

  .log-out-button {
    background: transparent;
  }
  
.login-container {
    padding: 10px; 
    height: 100vh;
}

.label-email {
    color: #393F3F;
    margin-bottom: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 20px;
}

.form-container-login {
    max-width: 35%;
    height: 25%;
    padding: 50px;
    margin: 20px auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background-color:#f2f7f8;
    border-radius: 15px;
    box-shadow: inset 0 -3em 3em rgba(193, 205, 224, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(114, 147, 218, 0.3);
}

.form-inputs-login {
    margin-bottom: 30px;
    margin-top: 10px;
    height: 35px;
    padding: 5px;
    font-size: 16px;
    width: 80%;
}

.email-login {
    // background-color: #0B3954;
}

.form-inputs-login input {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 1.6rem;
}

.button-30 {
  align-items: center;
  appearance: none;
  background-color: #FCFCFD;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395A;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
}

.button-30:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.button-30:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-30:active {
  box-shadow: #D6D6E7 0 3px 7px inset;
  transform: translateY(2px);
}

.h1-login {
    color: brown;
    font-size: 3rem;
    text-align: center;
    padding: 10px;
    padding-top: 40px;
    letter-spacing: 5px;
    font-weight: bold;
}

.error-container-login {
    width: 500px;
    text-align: center;
    padding: 5px;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
}

.link-to-login {
    font-size: 20px;
}

.error-message-login {
    font-weight: bold;
    color: red;
    padding: 10px;
    font-size: large;
}

.input-checkbox-login {
    position: relative;
    margin-top: 50px;
}

.show-pass-btn-login {
    position: absolute;
    background-color: transparent;
    right: 0;
    top: 0;
    margin-top: 38px;
    margin-right: 600px;
    width: 65px;
    cursor: pointer;
    
}

.show-pass-btn-login > span {
    fill: gray;
}

.link-to-sign-up {
    color: #0B3954;
    margin-bottom: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 20px;
}

.home-icon {
    max-width: 50px;
    left: 0;
    position: absolute;
    padding: 5px;
    cursor: pointer;
    margin-left: 15px;
}
// Primary font and secondary font (secondary font is optional)
$fontPrimary: Arial, sans-serif;
$fontSecondary: Helvetica, sans-serif;
$fontButton: 'Share Tech Mono', monospace;

// Colors including: white, black, several sets of app colors (primary-color, secondary-color, tertiary, etc.)
$primaryColor: #e3f7f5;
$secondaryColor: #393F3F;
$tertiaryColor: #0B3954;
$white: #F2F2F2;
$black: #222222;

// Background color (background of page)
$background: #FDFDFF;

// Font weights
$fontNormal: 400;
$fontBold: 700;
$fontThin: 100;


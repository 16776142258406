@import 'reset';
@import 'variables';
@import 'elements';
@import '../Components/Login/Login';
@import '../Components/HomePage/Home';
@import '../Components/SignUp/SignUp';
@import '../Components/CreateRecipe/createRecipe';
@import '../Components/singleRecipe/singleRecipe';
@import '../Components/Footer/Footer';

.content {
    padding-top: 55px;
    margin-bottom: 5px;
}

.night-mode-bg {
    background-color: cadetblue;
    height: fit-content;
    padding-top: 5px;
    padding-bottom: 30px;
}

.day-mode-bg {
    background: 100vh;
    height: fit-content;
    padding-top: 5px;
    padding-bottom: 30px;
}